import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function SelectSettlementType(props) {
  const { t } = useTranslation();
  const { type, setType } = props;

  const mergedType = "merged";
  const originalType = "org";

  useEffect(() => {
    setType(type ?? mergedType);
  }, [setType, type]);

  const handleTypeChange = () => {
    setType(type === mergedType ? originalType : mergedType);
  };

  return (
    <div className="form-check form-switch">
      <input
        className="form-check-input"
        type="checkbox"
        id="flexSwitchCheckDefault"
        onChange={() => handleTypeChange()}
        checked={type === mergedType}
      />
      <label className="form-check-label">
        {t("setting.merge_group_debt")}
      </label>
    </div>
  );
}
