import { useEffect } from "react";
import { useTranslation } from 'react-i18next';

export default function SelectTransactionType(props) {
  const { t } = useTranslation();
  const { type, setType } = props;
  const exactType = "exact";
  const shareType = "share";

  useEffect(() => {
    if (!type) {
      setType(exactType);
    }
  }, [type, setType]);

  return (
    <ul className="nav nav-tabs card-header-tabs">
      <li className="nav-item">
        <a
          className={`small nav-link ${type === exactType ? "active" : ""}`}
          onClick={() => setType(exactType)}
          href="#!"
        >
          {t('transaction.exact_amounts')}
        </a>
      </li>
      <li className="nav-item">
        <a
          className={`small nav-link ${type === shareType ? "active" : ""}`}
          onClick={() => setType(shareType)}
          href="#!"
        >
          {t('transaction.shares')}
        </a>
      </li>
    </ul>
  );
}
