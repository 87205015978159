import { useEffect, useState } from "react";
import ExactTransactionContributorRow from "../components/ExactTransactionContributorRow";

export default function ExactTransactionContributorList(props) {
  const {
    contributors,
    amount,
    setContributorAmountList,
    contributorAmountIsEditing,
    setContributorAmountIsEditing,
    transaction,
    newTransaction,
  } = props;
  const [dividedAmountList, setDividedAmountList] = useState([]);

  useEffect(() => {
    const divideAmount = (amount, shares) => {
      const result = [];

      for (let i = 0; i < shares - 1; i++) {
        result.push(+(amount / shares).toFixed(2));
      }
      result.push(+(amount - result.reduce((a, b) => a + b, 0)).toFixed(2));

      return result;
    };

    setDividedAmountList(divideAmount(amount, contributors.length));
  }, [amount, contributors]);

  return (
    <ul className="list-group list-group-flush">
      {contributors &&
        contributors.map((c, i) => (
          <li className="list-group-item" key={c._id}>
            <ExactTransactionContributorRow
              contributor={c}
              dividedAmount={dividedAmountList[i]}
              setContributorAmountList={setContributorAmountList}
              contributorAmountIsEditing={contributorAmountIsEditing}
              setContributorAmountIsEditing={setContributorAmountIsEditing}
              newTransaction={newTransaction}
              transaction={transaction}
            />
          </li>
        ))}
    </ul>
  );
}
