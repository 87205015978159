import React, { useState, useEffect, useCallback } from "react";
import { putGroup } from "../services/AnimalSplitCore";
import SelectSettlementType from "../components/SelectSettlementType";
import { toggleModal } from "../utils/utils";
import { MODAL_ID } from "../constants";
import SelectLanguage from "../components/SelectLanguage";
import { useTranslation } from "react-i18next";

export default function EditGroup(props) {
  const { t, i18n } = useTranslation();
  const {
    modalId,
    groupId,
    renderGroup,
    groupName,
    settlementType,
    setIsLoading,
  } = props;
  const [newGroupName, setNewGroupName] = useState("");
  const [newSettlementType, setNewSettlementType] = useState("");
  const [formValidated, setFormValidated] = useState(false);
  const [newLanguage, setNewLanguage] = useState("");

  const handleSave = async () => {
    if (!isFormValid()) {
      setFormValidated(true);
      return;
    }

    try {
      setIsLoading(true);
      const { payload } = await putGroup(groupId, {
        name: newGroupName,
        settlementType: newSettlementType,
        lang: newLanguage,
      });
      setIsLoading(false);
      renderGroup(payload);
      i18n.changeLanguage(newLanguage);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      toggleModal(MODAL_ID.CONNECTION_ERROR);
    }
  };

  useEffect(() => {
    // Init bootstrap tooltip component
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new window.bootstrap.Tooltip(tooltipTriggerEl);
    });
  }, []);

  useEffect(() => {
    setNewSettlementType(settlementType);
    setNewGroupName(groupName);
    setNewLanguage(i18n.language);
  }, [settlementType, groupName, i18n.language]);

  const handleModalWillShow = useCallback(() => {
    setNewSettlementType(settlementType);
    setFormValidated(false);
    setNewLanguage(i18n.language);

    // Reset group name
    const groupNameFloatingInput = document.getElementById(
      `${modalId}-floatingInput`
    );
    groupNameFloatingInput.value = groupName;
  }, [modalId, settlementType, groupName, setFormValidated, i18n.language]);

  useEffect(() => {
    handleModalWillShow();
    const editGroupModal = document.getElementById(modalId);
    editGroupModal.addEventListener("show.bs.modal", handleModalWillShow);

    return () => {
      editGroupModal.removeEventListener("show.bs.modal", handleModalWillShow);
    };
  }, [modalId, handleModalWillShow]);

  const isFormValid = () => {
    return newGroupName !== "";
  };

  return (
    <div className="modal fade" id={modalId} tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {t("setting.title")}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
            ></button>
          </div>

          <div className={`modal-body ${formValidated ? "was-validated" : ""}`}>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id={`${modalId}-floatingInput`}
                placeholder="Trip to Mammoth Lake"
                onChange={(e) => setNewGroupName(e.target.value)}
                defaultValue={groupName}
                required
              />
              <label>{t("event.title")}</label>
            </div>
            <div className="d-flex align-items-center">
              <SelectSettlementType
                className="d-block"
                type={newSettlementType}
                setType={setNewSettlementType}
              />
              <button
                className="ms-2 btn btn-link"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-original-title={t("setting.merge_hint")}
                title={t("setting.merge_hint")}
              >
                <i className="bi-question-circle" />
              </button>
            </div>
            <div>
              <label className="form-label d-block mt-3">
                {t("setting.language")}
              </label>
              <SelectLanguage
                className="d-block"
                type={newLanguage}
                setType={setNewLanguage}
              />
            </div>

            <div>
              <label className="form-label mt-3">{`${t(
                "event.event_id"
              )}:`}</label>{" "}
              <p className="d-inline">
                <small>{groupId}</small>
              </p>
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              {t("general.close")}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSave}
              data-bs-dismiss={`${isFormValid() ? "modal" : ""}`}
            >
              {t("general.save")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
