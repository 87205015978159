import { amountDataToDisplay } from "../utils/utils";
import { useTranslation } from 'react-i18next';

export default function ContributorSettlementRow(props) {
  const { t } = useTranslation();
  const { name, amount } = props;

  return (
    <div className="ms-4 d-flex align-items-center">
      <i className="bi-play-fill me-1" />
      <div className="truncate">{t('general.owes')} {`${name} ${amountDataToDisplay(amount)}`}</div>
    </div>
  );
}
