import React from "react";
import { useHistory } from "react-router-dom";
import Navbar from "../components/Navbar";
import "./Landing.css";
import { useTranslation } from "react-i18next";

export default function Landing() {
  const { t } = useTranslation();
  const history = useHistory();

  const handleStart = () => {
    history.push(`${process.env.PUBLIC_URL}/group/new`);
  };

  return (
    <div className="d-flex flex-column inner-height landing">
      <Navbar
        actionBtnIconClassName="bi-info-circle"
        showActionBtn={true}
        handleActionBtnOnClick={() =>
          history.push(`${process.env.PUBLIC_URL}/terms`)
        }
      />
      <div className="container flex-grow-1 text-center d-flex flex-column justify-content-center">
        <div>
          <h1>AnimalSplit</h1>
          <h2>🐶🐱🐰🦊🐻🐼🐨🐯🦁🐮🐷🐸🐵</h2>
          <p>{t("general.slogan")}</p>
          <button className="btn btn-primary btn-lg" onClick={handleStart}>
            {t("general.start")}
          </button>
        </div>
      </div>
    </div>
  );
}
