import React from "react";
import { ICONS, TRANSACTION_CATEGORIES, MODAL_ID } from "../constants";
import { deleteTransaction } from "../services/AnimalSplitCore";
import { amountDataToDisplay, toggleModal } from "../utils/utils";
import { useTranslation } from 'react-i18next';

export default function TransactionRow(props) {
  const { t } = useTranslation();
  const {
    modalId,
    setTargetTransaction,
    groupId,
    transaction,
    renderGroup,
    setNewTransaction,
    setIsLoading,
  } = props;
  const { contributor } = transaction;

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      const { _id: transactionId } = transaction;
      const { payload } = await deleteTransaction(groupId, transactionId);
      setIsLoading(false);
      renderGroup(payload);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      toggleModal(MODAL_ID.CONNECTION_ERROR);
    }
  };

  const dataReady = () => {
    return (
      transaction &&
      transaction.category &&
      contributor &&
      contributor.icon &&
      contributor.name
    );
  };

  if (!dataReady()) return <div></div>;

  return (
    <div className="d-flex align-items-center justify-content-between">
      <div className="truncate d-flex align-items-center">
        <div className="fs-3 me-2">
          {TRANSACTION_CATEGORIES[transaction.category]}
        </div>
        <div>
          <div className="truncate">{transaction.title}</div>
          <div className="fs-6 fw-light text-muted">
          {`${ICONS[contributor.icon]} ${contributor.name} `} 
          {t('general.paid')} {' '} 
          {`${amountDataToDisplay(transaction.amount)}`}</div>
        </div>
      </div>
      <div className="btn-group">
        <button
          className="btn btn-outline-primary btn-sm me-1"
          data-bs-toggle="modal"
          data-bs-target={`#${modalId}`}
          onClick={() => {
            setNewTransaction(false);
            setTargetTransaction(transaction);
          }}
        >
          <i className="bi-pencil-fill" />
        </button>
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={handleDelete}
        >
          <i className="bi-trash-fill" />
        </button>
      </div>
    </div>
  );
}
