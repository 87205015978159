import Navbar from "../components/Navbar";

export default function Terms() {
  return (
    <div className="d-flex flex-column inner-height">
      <Navbar />
      <div className="container my-5">
        <h1>AnimalSplit Terms of Service</h1>
        <h2>Terms of Use</h2>
        <p>
          AnimalSplit is designed for the use of a group of people to keep track
          of their expenses. You may use the services AnimalSplit provides if
          you agree to all of our Terms of Service.
        </p>
        <ul>
          <li>
            AnimalSplit does not offer legally binding contracts of any kind.
          </li>
          <li>AnimalSplit does not offer warranty of any kind.</li>
          <li>
            AnimalSplit may stop offering or restrict certain features or
            services at any time.
          </li>
          <li>
            In order to provide the services we have to collect some of your
            information. Please refer to our Privacy Policy section for more
            detail.
          </li>
          <li>
            AnimalSplit services, ArcLight Software LLC will not be liable to
            damages/losses of any kind that’s arised by using our services,
            under any circumstances.
          </li>
        </ul>
        <h2>Privacy Policy</h2>
        <p>
          AnimalSplit has to collect some data in order to provide our services.
          In this section we describe the kind of data we collect and how we use
          them.
        </p>
        <h3>Data we collect</h3>
        <ul>
          <li>User-generated content</li>
          <ul>
            <li>
              We collect and store the information you provide to us while using
              our services. Here are some examples of the data we collect: Event
              Name, Contributor Name, Transaction Amount etc.
            </li>
          </ul>
          <li>Surveys</li>
          <ul>
            <li>
              From time to time we may offer you opportunities to participate in
              surveys. We collect and store all data provided in the surveys.
            </li>
          </ul>
        </ul>
        <h3>How we use data</h3>
        <ul>
          <li>We use your data to provide the AnimalSplit services.</li>
          <li>We may use your data for internal data analytics purposes.</li>
          <li>
            We may use your data for internal software system error detections.
          </li>
        </ul>
      </div>
    </div>
  );
}
