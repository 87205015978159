import { ANIMAL_SPLIT_CORE } from "../constants";

const axios = require("axios").default;

export const getHelloWorld = async () => {
  try {
    const { data } = await axios.get(
      `${ANIMAL_SPLIT_CORE[process.env.NODE_ENV].URL}/api/v0/hello`
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const postGroup = async (
  groupName,
  contributorName,
  contributorIcon,
  lang
) => {
  try {
    const { data } = await axios.post(
      `${ANIMAL_SPLIT_CORE[process.env.NODE_ENV].URL}/api/v0/group/single`,
      { name: groupName, contributorName, contributorIcon, lang: lang }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const putGroup = async (groupId, contents) => {
  try {
    const { data } = await axios.put(
      `${ANIMAL_SPLIT_CORE[process.env.NODE_ENV].URL}/api/v0/group/${groupId}`,
      contents
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const getGroup = async (groupId) => {
  try {
    const { data } = await axios.get(
      `${ANIMAL_SPLIT_CORE[process.env.NODE_ENV].URL}/api/v0/group/${groupId}`
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const putContributor = async (groupId, contributorId, contents) => {
  try {
    const { data } = await axios.put(
      `${
        ANIMAL_SPLIT_CORE[process.env.NODE_ENV].URL
      }/api/v0/contributor/${groupId}/${contributorId}`,
      contents
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const postContributor = async (groupId, contents) => {
  try {
    const { data } = await axios.post(
      `${
        ANIMAL_SPLIT_CORE[process.env.NODE_ENV].URL
      }/api/v0/contributor/${groupId}`,
      contents
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteContributor = async (groupId, contributorId) => {
  try {
    const { data } = await axios.delete(
      `${
        ANIMAL_SPLIT_CORE[process.env.NODE_ENV].URL
      }/api/v0/contributor/${groupId}/${contributorId}`
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const postTransaction = async (groupId, contents) => {
  try {
    const { data } = await axios.post(
      `${
        ANIMAL_SPLIT_CORE[process.env.NODE_ENV].URL
      }/api/v0/transaction/${groupId}`,
      contents
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const putTransaction = async (groupId, transactionId, contents) => {
  try {
    const { data } = await axios.put(
      `${
        ANIMAL_SPLIT_CORE[process.env.NODE_ENV].URL
      }/api/v0/transaction/${groupId}/${transactionId}`,
      contents
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteTransaction = async (groupId, transactionId) => {
  try {
    const { data } = await axios.delete(
      `${
        ANIMAL_SPLIT_CORE[process.env.NODE_ENV].URL
      }/api/v0/transaction/${groupId}/${transactionId}`
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteAffectedContributor = async (
  groupId,
  transactionId,
  contributorId
) => {
  try {
    const { data } = await axios.delete(
      `${
        ANIMAL_SPLIT_CORE[process.env.NODE_ENV].URL
      }/api/v0/affected/${groupId}/${transactionId}/${contributorId}`
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const postAffectedContributor = async (
  groupId,
  transactionId,
  contributorId
) => {
  try {
    const { data } = await axios.post(
      `${
        ANIMAL_SPLIT_CORE[process.env.NODE_ENV].URL
      }/api/v0/affected/${groupId}/${transactionId}`,
      { contributorId }
    );
    return data;
  } catch (error) {
    throw error;
  }
};
