import React, { useState, useEffect, useCallback } from "react";
import SelectContributor from "../components/SelectContributor";
import { postTransaction, putTransaction } from "../services/AnimalSplitCore";
import { amountInputToData, amountDataToDisplay } from "../utils/utils";
import SelectTransactionType from "../components/SelectTransactionType";
import ExactTransactionContributorList from "./ExactTransactionContributorList";
import ShareTransactionContributorList from "./ShareTransactionContributorList";
import "./EditTransaction.css";
import { TRANSACTION_CATEGORIES, MODAL_ID } from "../constants";
import { toggleModal } from "../utils/utils";
import { useTranslation } from "react-i18next";

export default function EditTransaction(props) {
  const { t } = useTranslation();
  const shareType = "share";
  const exactType = "exact";

  const {
    newTransaction,
    transaction,
    modalId,
    groupId,
    contributors,
    renderGroup,
    setIsLoading,
  } = props;
  const [title, setTitle] = useState("");
  const [amount, setAmount] = useState("");
  const [contributorId, setContributorId] = useState("");
  const [category, setCategory] = useState("");
  const [type, setType] = useState("");
  const [contributorAmountList, setContributorAmountList] = useState({});
  const [contributorAmountIsEditing, setContributorAmountIsEditing] =
    useState(false);
  const [contributorShareList, setContributorShareList] = useState({});
  const [formValidated, setFormValidated] = useState(false);
  const [isContributionInvalid, setIsContributionInvalid] = useState(false);

  const isFormValid = useCallback(() => {
    const formInvalid = title === "" || amount === "";
    let contributionInvalid = false;

    switch (type) {
      case exactType:
        let contributorTotal = 0;
        Object.keys(contributorAmountList).forEach((contributorId) => {
          contributorTotal += contributorAmountList[contributorId].amount;
        });
        if (contributorTotal !== amountInputToData(amount)) {
          contributionInvalid = true;
        }
        break;
      default:
        let shareTotal = 0;
        for (let i = 0; i < Object.keys(contributorShareList).length; i++) {
          const share =
            contributorShareList[Object.keys(contributorShareList)[i]].share;
          if (share < 0) {
            contributionInvalid = true;
          }
          shareTotal += share;
        }
        if (shareTotal === 0) {
          contributionInvalid = true;
        }
        break;
    }

    if (isContributionInvalid !== contributionInvalid)
      setIsContributionInvalid(contributionInvalid);

    return !contributionInvalid && !formInvalid;
  }, [
    amount,
    title,
    contributorAmountList,
    contributorShareList,
    isContributionInvalid,
    type,
  ]);

  const handleModalWillShow = useCallback(() => {
    if (newTransaction) {
      setTitle("");
      setAmount("");
      setContributorId("");
      setCategory("other");
      setType("");
      setContributorAmountList({});
      setContributorAmountIsEditing(false);
      setContributorShareList({});
      setFormValidated(false);
      setIsContributionInvalid(false);
    } else {
      setTitle(transaction.title);
      setAmount(`${amountDataToDisplay(transaction.amount)}`);
      setContributorId(transaction.contributorId);
      setCategory(transaction.category);
      setType(transaction.type);
      setContributorAmountIsEditing(true);
      setFormValidated(false);
      setIsContributionInvalid(false);
      if (transaction.type === shareType) {
        setContributorAmountList({});
        setContributorShareList(transaction.affectedContributors);
      } else if (transaction.type === exactType) {
        setContributorAmountList(transaction.affectedContributors);
        setContributorShareList({});
      }
    }
  }, [newTransaction, transaction]);

  useEffect(() => {
    handleModalWillShow();
    const editTransactionModal = document.getElementById(modalId);
    editTransactionModal.addEventListener("show.bs.modal", handleModalWillShow);
    return () => {
      editTransactionModal.removeEventListener(
        "show.bs.modal",
        handleModalWillShow
      );
    };
  }, [modalId, handleModalWillShow]);

  const handleSetCategory = (newCategory) => {
    if (t("transaction.category." + category) === title || title === "") {
      const categoryTitle = newCategory.replace(category[0], category[0]);
      setTitle(t("transaction.category." + categoryTitle));
    }
    setCategory(newCategory);
  };

  const handleSave = async () => {
    if (!isFormValid()) {
      setFormValidated(true);
      return;
    }
    setIsLoading(true);
    if (newTransaction) {
      const affectedContributors =
        type === exactType ? contributorAmountList : contributorShareList;

      // Populate default data for non selected transaction type
      if (type === exactType) {
        Object.keys(affectedContributors).forEach((contributorId) => {
          affectedContributors[contributorId].share = 1;
        });
      } else {
        let averageAmount = Math.round(
          amountInputToData(amount) / Object.keys(affectedContributors).length
        );
        let accu_sum = 0;
        Object.keys(affectedContributors).forEach((contributorId, i) => {
          let contributorAmount = averageAmount;
          if (i === Object.keys(affectedContributors).length - 1)
            contributorAmount = amountInputToData(amount) - accu_sum;
          accu_sum += contributorAmount;
          affectedContributors[contributorId].amount = contributorAmount;
        });
      }

      try {
        const { payload } = await postTransaction(groupId, {
          title,
          amount: amountInputToData(amount),
          contributorId,
          type,
          category,
          affectedContributors,
        });
        setIsLoading(false);
        renderGroup(payload);
      } catch (error) {
        setIsLoading(false);
        console.error(error);
        toggleModal(MODAL_ID.CONNECTION_ERROR);
      }
    } else {
      const { _id: transactionId } = transaction;
      const affectedContributors =
        type === exactType ? contributorAmountList : contributorShareList;

      // Inject non-selected type value
      const nonSelectedType = type === exactType ? shareType : "amount";
      Object.keys(affectedContributors).forEach((k) => {
        if (!transaction.affectedContributors[k]) return;
        affectedContributors[k][nonSelectedType] =
          transaction.affectedContributors[k][nonSelectedType];
      });

      try {
        const { payload } = await putTransaction(groupId, transactionId, {
          amount: amountInputToData(amount),
          title,
          type,
          contributorId,
          category,
          affectedContributors,
        });
        setIsLoading(false);
        renderGroup(payload);
      } catch (error) {
        setIsLoading(false);
        console.error(error);
        toggleModal(MODAL_ID.CONNECTION_ERROR);
      }
    }
  };

  return (
    <div className="modal fade" id={modalId} tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            {(newTransaction && (
              <h5 className="modal-title" id="exampleModalLabel">
                {t("transaction.create_transaction")}
              </h5>
            )) || (
              <h5 className="modal-title" id="exampleModalLabel">
                {t("transaction.edit_transaction")}
              </h5>
            )}
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
            ></button>
          </div>

          <div className={`modal-body ${formValidated ? "was-validated" : ""}`}>
            <div className="col">
              <div className="d-flex flex-wrap">
                {Object.entries(TRANSACTION_CATEGORIES).map(([key, value]) => (
                  <div className="d-inline-block mb-1 mx-1" key={key}>
                    <div className="d-flex flex-column justify-items-center">
                      <button
                        type="button"
                        id={key}
                        className={`btn ${
                          category === key
                            ? "btn-outline-warning"
                            : "btn-outline-light"
                        } btn-sm`}
                        onClick={() => handleSetCategory(key)}
                      >
                        <h3 className="m-0">{value}</h3>
                      </button>
                      <p className="text-center text-wrap category-label">
                        {t(`transaction.category.${key}`)}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="mb-3">
              <label className="form-label">
                {t("transaction.description")}
              </label>
              <input
                type="text"
                className="form-control"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </div>
            <div className="mb-3 row">
              <div className="edit-transaction-amount">
                <label className="form-label">{t("transaction.amount")}</label>

                <input
                  type="number"
                  className="form-control"
                  value={amount}
                  onChange={(e) => {
                    setContributorAmountIsEditing(false);
                    setAmount(e.target.value);
                  }}
                  required
                />
              </div>
              <div className="col">
                <label className="form-label">
                  {t("transaction.contributor")}
                </label>
                <SelectContributor
                  contributors={contributors}
                  contributorId={contributorId}
                  setContributorId={setContributorId}
                />
              </div>
            </div>
          </div>
          <div className="card-header d-flex justify-content-between align-items-end">
            <div>{t("transaction.split_by")}</div>
            <SelectTransactionType type={type} setType={setType} />
          </div>
          {type === exactType && (
            <ExactTransactionContributorList
              contributors={contributors}
              category={category}
              amount={amount}
              setContributorAmountList={setContributorAmountList}
              contributorAmountIsEditing={contributorAmountIsEditing}
              setContributorAmountIsEditing={setContributorAmountIsEditing}
              transaction={transaction}
              newTransaction={newTransaction}
            />
          )}
          {type === shareType && (
            <ShareTransactionContributorList
              contributors={contributors}
              category={category}
              setContributorShareList={setContributorShareList}
              transaction={transaction}
              newTransaction={newTransaction}
            />
          )}
          <div className="modal-footer">
            {formValidated && isContributionInvalid && type === exactType && (
              <p className="danger-color">Invalid contributor amount</p>
            )}
            {formValidated && isContributionInvalid && type === shareType && (
              <p className="danger-color">Invalid share value</p>
            )}
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              {t("general.close")}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              data-bs-dismiss={`${isFormValid() ? "modal" : ""}`}
              onClick={handleSave}
            >
              {t("general.save")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
