import { useEffect } from "react";
import { ICONS } from "../constants";
import "./SelectContributor.css";
import { useRef } from "react";

export default function SelectContributor(props) {
  const { contributors, contributorId, setContributorId } = props;
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (!contributorId && contributors && contributors.length > 0) {
      setContributorId(contributors[0]._id);
    }
  }, [contributors, contributorId, setContributorId]);

  const scrollToView = ()=>{
    dropdownRef.current.focus();
  };

  return (
    <div className="dropdown">
      <button
        className="btn btn-outline-secondary dropdown-toggle select-contributor-button"
        data-bs-toggle="dropdown"
        onClick={scrollToView}
      >
        {`${ICONS[contributors.find((c) => c._id === contributorId)?.icon]} ${
          contributors.find((c) => c._id === contributorId)?.name
        }`}
      </button>
      <div
        className="dropdown-menu"
        onClick={(e) => {
          e.preventDefault();
          setContributorId(e.target.getAttribute("value"));
        }}
      >
        {contributors.map((c) => (
          <button
            ref={contributorId === c._id ? dropdownRef:null}
            className={`select-contributor-button dropdown-item text-center ${
              contributorId === c._id ? "active" : ""
            }`}
            key={c._id}
            value={c._id}
          >
            {`${ICONS[c.icon]} ${c.name}`}
          </button>
        ))}
      </div>
    </div>
  );
}
