import React, { useState, useEffect, useCallback } from "react";
import SelectContributorIcon from "../components/SelectContributorIcon";
import { postContributor } from "../services/AnimalSplitCore";
import { toggleModal } from "../utils/utils";
import { MODAL_ID } from "../constants";
import { useTranslation } from 'react-i18next';

export default function CreateContributor(props) {
  const { t } = useTranslation();
  const { modalId, groupId, renderGroup,setIsLoading } = props;

  const [contributorName, setContributorName] = useState("");
  const [contributorIconId, setContributorIconId] = useState("");
  const [formValidated, setFormValidated] = useState(false);

  const handleModalWillShow = useCallback(() => {
    setContributorName("");
    setContributorIconId("");
    setFormValidated(false);
  }, [setContributorName, setContributorIconId, setFormValidated]);

  useEffect(() => {
    const editContributorCreationModal = document.getElementById(modalId);
    editContributorCreationModal.addEventListener(
      "show.bs.modal",
      handleModalWillShow
    );

    return () => {
      editContributorCreationModal.removeEventListener(
        "show.bs.modal",
        handleModalWillShow
      );
    };
  }, [modalId, handleModalWillShow]);

  const handleCreateContributor = async () => {
    if (!isFormValid()) {
      setFormValidated(true);
      return;
    }

    try {
      setIsLoading(true);
      const { payload } = await postContributor(groupId, {
        name: contributorName,
        icon: contributorIconId,
      });
      setIsLoading(false);
      renderGroup(payload);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      toggleModal(MODAL_ID.CONNECTION_ERROR);
    }
  };

  const isFormValid = () => {
    return contributorName !== "";
  };

  return (
    <div className="modal fade" id={modalId} tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {t('participant.create_participant')}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
            ></button>
          </div>

          <div className={`modal-body ${formValidated ? "was-validated" : ""}`}>
            <label className="form-label">{t('participant.participant_name')}</label>
            <div className="d-flex">
              <input
                type="text"
                className="form-control me-2"
                id="participantName"
                value={contributorName}
                onChange={(e) => setContributorName(e.target.value)}
                required
              />
              <SelectContributorIcon
                setContributorIconId={setContributorIconId}
                contributorIconId={contributorIconId}
              />
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              {t('general.close')}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              data-bs-dismiss={`${isFormValid() ? "modal" : ""}`}
              onClick={handleCreateContributor}
            >
              {t('general.save')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
