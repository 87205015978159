import React, { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import Navbar from "../components/Navbar";
import SelectContributorIcon from "../components/SelectContributorIcon";
import { postGroup } from "../services/AnimalSplitCore";
import Prompt from "../components/Prompt";
import { toggleModal } from "../utils/utils";
import { MODAL_ID } from "../constants";
import { useTranslation } from "react-i18next";

export default function CreateGroup(props) {
  const { t, i18n } = useTranslation();
  const { setIsLoading } = props;
  const [eventName, setEventName] = useState("");
  const [participantName, setParticipantName] = useState("");
  const [contributorIconId, setContributorIconId] = useState("");
  const [formValidated, setFormValidated] = useState(false);
  const history = useHistory();

  const isFormValid = useCallback(() => {
    return eventName !== "" && participantName !== "";
  }, [eventName, participantName]);

  const createGroup = useCallback(() => {
    if (!isFormValid()) {
      setFormValidated(true);
      return;
    }
    setIsLoading(true);
    postGroup(eventName, participantName, contributorIconId, i18n.language)
      .then(({ payload }) => {
        const { _id: groupId } = payload;
        setIsLoading(false);
        history.push(`${process.env.PUBLIC_URL}/group/${groupId}`);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
        toggleModal(MODAL_ID.CONNECTION_ERROR);
      });
  }, [
    eventName,
    contributorIconId,
    participantName,
    i18n.language,
    history,
    isFormValid,
    setIsLoading,
  ]);

  return (
    <div className="d-flex flex-column inner-height">
      <Navbar />
      <div className="container flex-grow-1 d-flex flex-column justify-content-center">
        <div>
          <div className="row justify-content-center">
            <div className="col-xs-12 col-sm-10 col-md-7 col-lg-5">
              <h1>{t("event.create_a_new_event")}</h1>
              <div
                className={`needs-validation ${
                  formValidated ? "was-validated" : ""
                }`}
              >
                <div className="mb-3">
                  <label className="form-label">{t("event.title")}</label>
                  <input
                    type="text"
                    className="form-control"
                    id="eventName"
                    value={eventName}
                    onChange={(e) => setEventName(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label for="validationCustom01" className="form-label">
                    {t("participant.participant_name")}
                  </label>
                  <div className="d-flex">
                    <input
                      type="text"
                      className="form-control me-2"
                      id="participantName"
                      value={participantName}
                      onChange={(e) => setParticipantName(e.target.value)}
                      required
                    />
                    <SelectContributorIcon
                      setContributorIconId={setContributorIconId}
                      contributorIconId={contributorIconId}
                    />
                  </div>
                  <div id="participantHelp" className="form-text">
                    {t("event.participant_name_prompt")}
                  </div>
                </div>
                <button
                  className="btn btn-primary me-2"
                  onClick={(e) => {
                    e.preventDefault();
                    createGroup();
                  }}
                >
                  {t("general.submit")}
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={() => history.push(`${process.env.PUBLIC_URL}/`)}
                >
                  {t("general.cancel")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Prompt
        modalId={MODAL_ID.CONNECTION_ERROR}
        title="Oops"
        btnTitle="Dismiss"
      >
        <p>There is a connection error</p>
      </Prompt>
    </div>
  );
}
