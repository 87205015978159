import React from "react";
import ShareTransactionContributorRow from "../components/ShareTransactionContributorRow";

export default function ShareTransactionContributorList(props) {
  const { contributors, setContributorShareList, transaction, newTransaction } =
    props;

  return (
    <ul className="list-group list-group-flush">
      {contributors &&
        contributors.map((c) => (
          <li className="list-group-item" key={c._id}>
            <ShareTransactionContributorRow
              contributor={c}
              setContributorShareList={setContributorShareList}
              newTransaction={newTransaction}
              transaction={transaction}
            />
          </li>
        ))}
    </ul>
  );
}
