import { useEffect, useState } from "react";
import ContributorRow from "../components/ContributorRow";
import CreateContributor from "./CreateContributor";
import { useTranslation } from 'react-i18next';

export default function ContributorList(props) {
  const { t } = useTranslation();
  const {
    groupId,
    contributors,
    renderGroup,
    settlements,
    contributorIdDict,
    settlementType,
    setIsLoading,
  } = props;
  const modalId = "create-contributor-modal";
  const [collapse, setCollapse] = useState(false);

  useEffect(() => {
    const collapseCard = document.getElementById("collapseCardContributor");
    var bsCollapseCard = new window.bootstrap.Collapse(collapseCard, {
      toggle: false,
    });

    collapse ? bsCollapseCard.hide() : bsCollapseCard.show();
  }, [collapse]);

  return (
    <div className="card shadow-sm">
      <div
        className="card-header cursor-pointer d-flex justify-content-between align-items-center"
        onClick={() => setCollapse(!collapse)}
      >
        <div>
          <strong>{t('participant.title')}</strong>
        </div>
        <i className={`${collapse ? "bi-chevron-down" : "bi-chevron-up"}`}></i>
      </div>
      <div className="collapse show" id="collapseCardContributor">
        <ul className="list-group list-group-flush">
          {contributors.map((c) => {
            return (
              <li className="list-group-item" key={c._id}>
                <ContributorRow
                  groupId={groupId}
                  contributor={c}
                  renderGroup={renderGroup}
                  settlements={settlements}
                  contributorIdDict={contributorIdDict}
                  settlementType={settlementType}
                  setIsLoading = {setIsLoading}
                />
              </li>
            );
          })}
        </ul>
        <div className="card-body">
          <button
            className={`btn btn-primary position-absolute top-100 end-0 translate-middle-y me-3 shadow round ${
              collapse ? "invisible" : "visible"
            }`}
            data-bs-toggle="modal"
            data-bs-target={`#${modalId}`}
          >
            <i className="bi-plus-lg" />
          </button>
        </div>
      </div>

      <CreateContributor
        modalId={modalId}
        groupId={groupId}
        renderGroup={renderGroup}
        setIsLoading = {setIsLoading}
      />
    </div>
  );
}
