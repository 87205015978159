import React from "react";
import { ICONS } from "../constants";
import EditContributor from "../containers/EditContributor";
import ContributorSettlementRow from "./ContributorSettlementRow";
import RemoveContributorPrompt from "../containers/RemoveContributorPrompt";
import { toggleModal } from "../utils/utils";

export default function ContributorRow(props) {
  const {
    groupId,
    contributor,
    renderGroup,
    settlements,
    contributorIdDict,
    settlementType,
    setIsLoading,
  } = props;
  const modalId = `edit-contributor-modal-${contributor._id}`;
  const removeContributorPromptModalId = `remove-contributor-prompt-modal-${contributor._id}`;

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <div className="truncate d-flex align-items-center">
          <div className="d-inline fs-3 me-1">{ICONS[contributor.icon]}</div>
          <div className="truncate d-inline">{contributor.name}</div>
        </div>
        <div className="btn-group">
          <button
            className="btn btn-outline-primary btn-sm me-1"
            data-bs-toggle="modal"
            data-bs-target={`#${modalId}`}
          >
            <i className="bi-pencil-fill" />
          </button>
          <button
            className="btn btn-outline-danger btn-sm"
            onClick={() => toggleModal(removeContributorPromptModalId)}
          >
            <i className="bi-trash-fill" />
          </button>
        </div>

        <EditContributor
          modalId={modalId}
          groupId={groupId}
          contributor={contributor}
          renderGroup={renderGroup}
          setIsLoading = {setIsLoading}
        />
      </div>
      <div>
        {settlements?.[settlementType]?.[contributor._id]?.map((settlement) => {
          return (
            <ContributorSettlementRow
              key={settlement?.target}
              name={contributorIdDict?.[settlement?.target]?.name}
              amount={settlement?.amount}
            />
          );
        })}
      </div>
      <RemoveContributorPrompt
        modalId={removeContributorPromptModalId}
        groupId={groupId}
        renderGroup={renderGroup}
        contributor={contributor}
        setIsLoading = {setIsLoading}
      />
    </div>
  );
}
