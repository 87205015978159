import { ICONS } from "../constants";
import { useState, useEffect } from "react";
import "./ShareTransactionContributorRow.css";
import { useTranslation } from 'react-i18next';

export default function ShareTransactionContributorRow(props) {
  const { t } = useTranslation();
  const { contributor, setContributorShareList, transaction, newTransaction } =
    props;
  const [share, setShare] = useState("");
  const shareType = "share";

  useEffect(() => {
    setContributorShareList((prevState) => ({
      ...prevState,
      [contributor._id]: { share: parseInt(share) || 0, amount: 0 },
    }));
  }, [share, setContributorShareList, contributor]);

  useEffect(() => {
    if (newTransaction) {
      setShare("1");
    } else {
      if (transaction.type === shareType && transaction.affectedContributors) {
        setShare(
          `${transaction.affectedContributors[contributor._id]?.share ?? 0}`
        );
      } else {
        setShare("1");
      }
    }
  }, [contributor, transaction, newTransaction]);

  return (
    <div className="d-flex align-items-center">
      <div className="col-6">
        <div className="d-flex align-items-center ms-sm-4">
          <div className="d-inline fs-3 me-1">{ICONS[contributor.icon]}</div>
          <div className="d-inline text-truncate">{contributor.name}</div>
        </div>
      </div>
      <div className="col-6">
        <div className="d-flex justify-content-end me-sm-4">
          <div className="">
            <label className="form-label me-2">
              <small>{t('transaction.shares')}</small>
            </label>
            <div className="d-flex">
              <button
                className="btn btn-link"
                onClick={() => setShare(`${(parseInt(share) || 0) - 1}`)}
              >
                <i className="bi-dash-circle-fill" />
              </button>
              <input
                type="number"
                className="form-control share-transaction-contributor-row-input text-center"
                value={share}
                onChange={(e) => {
                  setShare(e.target.value);
                }}
              />
              <button
                className="btn btn-link"
                onClick={() => setShare(`${(parseInt(share) || 0) + 1}`)}
              >
                <i className="bi-plus-circle-fill" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
