export const amountDataToDisplay = (data) => {
  return `${(data / 100).toFixed(2)}`;
};

export const amountInputToData = (input) => {
  return Math.round(parseFloat(input) * 100);
};

export const toggleModal = (modalId) => {
  const modal = new window.bootstrap.Modal(document.getElementById(modalId));
  modal.toggle();
};
