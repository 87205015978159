import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const options = 
{
  // order and from where user language should be detected
  order: ['navigator', 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'htmlTag', 'path', 'subdomain'],

  // other options:
  // // keys or params to lookup language from
  // lookupQuerystring: 'lng',
  // lookupCookie: 'i18next',
  // lookupLocalStorage: 'i18nextLng',
  // lookupSessionStorage: 'i18nextLng',
  // lookupFromPathIndex: 0,
  // lookupFromSubdomainIndex: 0,

  // // cache user language on
  // caches: ['localStorage', 'cookie'],
  // excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // // optional expire and domain for set cookie
  // cookieMinutes: 10,

  // // optional htmlTag with lang attribute, the default is:
  // htmlTag: document.documentElement,

  // // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
  // cookieOptions: { path: '/', sameSite: 'strict' }
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: options,
    fallbackLng: 'en',
  })

export default i18n;