import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function SelectLanguage (props) {
  const { t } = useTranslation();
  const { type, setType } = props;

  const tw = "zh-TW";
  const cn = "zh-CN";
  const en = "en";

  useEffect(() => {
    setType(type);
  }, [setType, type]);

  const handleTypeChange = (lng) => {
    setType(lng);
  };

  return (
    <div
        className="btn-group"
        role="group"
        aria-label="Basic radio toggle button group"
      >
        <input
          type="radio"
          className="btn-check"
          checked={type === en}
          readOnly
        />
        <label
          className="btn btn-outline-primary"
          onClick={() => handleTypeChange(en)}
        >
          {t('setting.en')}
        </label>

        <input
          type="radio"
          className="btn-check"
          checked={type === cn}
          readOnly
        />
        <label
          className="btn btn-outline-primary"
          onClick={() => handleTypeChange(cn)}
        >
          {t('setting.cn')}
        </label>

        <input
          type="radio"
          className="btn-check"
          checked={type === tw}
          readOnly
        />
        <label
          className="btn btn-outline-primary"
          onClick={() => handleTypeChange(tw)}
        >
          {t('setting.tw')}
        </label>
      </div>
  )
}