import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { getGroup } from "../services/AnimalSplitCore";
import ContributorList from "./ContributorList";
import TransactionList from "./TransactionList";
import Navbar from "../components/Navbar";
import EditGroup from "./EditGroup";
import { toggleModal } from "../utils/utils";
import Prompt from "../components/Prompt";
import { MODAL_ID } from "../constants";
import { useTranslation } from "react-i18next";

export default function Group(props) {
  const { setIsLoading } = props;
  const { i18n } = useTranslation();
  const { group_id: groupId } = useParams();
  const [groupName, setGroupName] = useState("");
  const [contributors, setContributors] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [settlements, setSettlements] = useState({});
  const [settlementType, setSettlementType] = useState("");
  const [contributorIdDict, setContributorIdDict] = useState({});
  const [sortOrder, setSortOrder] = useState("");
  const [getGroupError, setGetGroupError] = useState(null);
  const editGroupModalId = "edit-group-modal";

  useEffect(() => {
    if (getGroupError) {
      console.error(getGroupError);
      throw getGroupError;
    }
  }, [getGroupError]);

  const renderGroup = useCallback(
    (payload) => {
      const {
        name,
        contributors: contributors_data,
        transactions: transactions_data,
        settlements: settlements_data,
        settlementType: settlement_type_data,
        sortOrder: sort_order_data,
        lang,
      } = payload;

      setGroupName(name);
      setContributors(contributors_data);
      setTransactions(transactions_data);
      setSettlements(settlements_data);
      setSettlementType(settlement_type_data);
      setSortOrder(sort_order_data);
      i18n.changeLanguage(lang);

      const contributorIdDict = {};
      contributors_data.forEach((c) => (contributorIdDict[c._id] = c));
      setContributorIdDict(contributorIdDict);
    },
    [i18n]
  );

  const refreshGroup = useCallback(
    (groupId) => {
      setIsLoading(true);
      getGroup(groupId)
        .then(({ payload }) => {
          setIsLoading(false);
          renderGroup(payload);
        })
        .catch((error) => {
          setIsLoading(false);
          setGetGroupError(error);
        });
    },
    [renderGroup, setIsLoading]
  );

  const handleSettingsBtnOnClick = useCallback(() => {
    toggleModal(editGroupModalId);
  }, [editGroupModalId]);

  useEffect(() => {
    refreshGroup(groupId);
  }, [refreshGroup, groupId]);

  return (
    <div>
      <Navbar
        actionBtnIconClassName="bi-gear-fill"
        showActionBtn={true}
        handleActionBtnOnClick={handleSettingsBtnOnClick}
      />
      <div className="container">
        <h3 className="text-center mt-3">{groupName}</h3>
        <div className="row">
          <div className="col-md-6 col-lg-4 offset-lg-2 mt-3 mb-4">
            <TransactionList
              groupId={groupId}
              contributors={contributors}
              transactions={transactions}
              renderGroup={renderGroup}
              sortOrder={sortOrder}
              setIsLoading={setIsLoading}
            />
          </div>

          <div className="col-md-6 col-lg-4 mt-3">
            <ContributorList
              groupId={groupId}
              contributors={contributors}
              renderGroup={renderGroup}
              settlements={settlements}
              contributorIdDict={contributorIdDict}
              settlementType={settlementType}
              setIsLoading={setIsLoading}
            />
          </div>
        </div>
      </div>
      <EditGroup
        modalId={editGroupModalId}
        groupId={groupId}
        renderGroup={renderGroup}
        groupName={groupName}
        settlementType={settlementType}
        setIsLoading={setIsLoading}
      />
      <Prompt
        modalId={MODAL_ID.CONNECTION_ERROR}
        title="Oops"
        btnTitle="Dismiss"
      >
        <p>There is a connection error</p>
      </Prompt>
    </div>
  );
}
