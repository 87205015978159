import React, { useState, useEffect } from "react";
import TransactionRow from "../components/TransactionRow";
import EditTransaction from "../containers/EditTransaction";
import { putGroup } from "../services/AnimalSplitCore";
import { toggleModal } from "../utils/utils";
import { MODAL_ID } from "../constants";
import { useTranslation } from "react-i18next";

export default function TransactionList(props) {
  const { t } = useTranslation();
  const {
    groupId,
    contributors,
    transactions,
    renderGroup,
    sortOrder,
    setIsLoading,
  } = props;
  const modalId = "edit-transaction-modal";
  const dropdownElement = document.getElementById("transaction-sort-dropdown");
  const dropdownInstance = dropdownElement
    ? new window.bootstrap.Dropdown(dropdownElement)
    : undefined;

  const contributorIdDict = {};
  contributors.forEach((c) => (contributorIdDict[c._id] = c));
  transactions.forEach(
    (t) => (t.contributor = contributorIdDict[t.contributorId])
  );

  const [newTransaction, setNewTransaction] = useState(false);
  const [targetTransaction, setTargetTransaction] = useState({});
  const [collapse, setCollapse] = useState(false);

  useEffect(() => {
    const collapseCard = document.getElementById("collapseCardTransaction");
    var bsCollapseCard = new window.bootstrap.Collapse(collapseCard, {
      toggle: false,
    });
    collapse ? bsCollapseCard.hide() : bsCollapseCard.show();
  }, [collapse]);

  const sortType = {
    default: t("transaction.sort_time"),
    contributor: t("participant.title"),
    amount: t("transaction.amount"),
    category: t("transaction.category_title"),
  };
  const sortIcon = {
    default: "bi bi-clock",
    contributor: "bi bi-people",
    amount: "bi bi-currency-dollar",
    category: "bi bi-tag",
  };

  //get transactions from backend when transactions updated
  const [displayTransactions, setDisplayTransactions] = useState([]);

  const [isReversed, setIsReversed] = useState(false);
  useEffect(() => {
    setDisplayTransactions(transactions);
    setIsReversed(false);
  }, [transactions, sortOrder]);

  useEffect(() => {
    var TransactionRowDisplay = document.getElementById(
      "transaction-row-display"
    );
    for (let i = 0; i < TransactionRowDisplay.childNodes.length; i++)
      TransactionRowDisplay.appendChild(TransactionRowDisplay.childNodes[i]);
  }, [isReversed]);

  const handleSave = async (selectedOrder) => {
    try {
      setIsLoading(true);
      const { payload } = await putGroup(groupId, {
        sortOrder: selectedOrder,
      });
      setIsLoading(false);
      renderGroup(payload);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      toggleModal(MODAL_ID.CONNECTION_ERROR);
    }
  };

  return (
    <div className="card shadow-sm">
      <div
        className="card-header cursor-pointer d-flex justify-content-between align-items-center"
        onClick={() => setCollapse(!collapse)}
      >
        <div>
          <strong>{t("transaction.title")}</strong>
        </div>
        <div>
          <small className="me-1">{t("transaction.sort")}</small>
          <button
            className="dropdownsortDropdown btn btn-outline-info btn-sm btn-block"
            id="transaction-sort-dropdown"
            data-bs-toggle="dropdown"
            onClick={(e) => {
              e.stopPropagation();
              dropdownInstance.toggle();
            }}
          >
            <div className={sortIcon[sortOrder]}></div>
          </button>
          <button
            className={`sortDropdown btn btn-outline-info btn-sm ms-1 me-2`}
            onClick={(e) => {
              e.stopPropagation();
              setIsReversed(!isReversed);
            }}
          >
            <div
              className={`${isReversed ? "bi bi-sort-up" : "bi bi-sort-down"}`}
            ></div>
          </button>
          <div
            className="dropdown-menu"
            onClick={(e) => {
              e.stopPropagation();
              dropdownInstance.toggle();
            }}
          >
            {Object.keys(sortType).map((k) => (
              <button
                className={`dropdown-item d-flex align-items-center ${
                  sortOrder === k ? "active" : ""
                }`}
                value={k}
                onClick={(e) => {
                  //e.stopPropagation();//for test use only
                  handleSave(k);
                }}
                key={k}
              >
                <div className={sortIcon[k]}></div>
                <div className="mx-2">{sortType[k]}</div>
              </button>
            ))}
          </div>
          <i
            className={`${collapse ? "bi-chevron-down" : "bi-chevron-up"}`}
          ></i>
        </div>
      </div>
      <div className="collapse show" id="collapseCardTransaction">
        <ul
          className="list-group list-group-flush"
          id="transaction-row-display"
        >
          {displayTransactions.map((t) => {
            return (
              <li className="list-group-item" key={t._id}>
                <TransactionRow
                  modalId={modalId}
                  setTargetTransaction={setTargetTransaction}
                  groupId={groupId}
                  transaction={t}
                  renderGroup={renderGroup}
                  setNewTransaction={setNewTransaction}
                  setIsLoading={setIsLoading}
                />
              </li>
            );
          })}
        </ul>
        <div className="card-body">
          <button
            className={`btn btn-primary position-absolute top-100 end-0 translate-middle-y me-3 shadow round ${
              collapse ? "invisible" : "visible"
            }`}
            data-bs-toggle="modal"
            data-bs-target={`#${modalId}`}
            onClick={() => setNewTransaction(true)}
          >
            <i className="bi-plus-lg" />
          </button>
        </div>
      </div>
      <EditTransaction
        newTransaction={newTransaction}
        transaction={targetTransaction}
        modalId={modalId}
        groupId={groupId}
        contributors={contributors}
        renderGroup={renderGroup}
        setIsLoading={setIsLoading}
      />
    </div>
  );
}
