export default function NavBar({
  actionBtnIconClassName = "",
  showActionBtn = false,
  handleActionBtnOnClick,
}) {
  return (
    <nav className="navbar navbar-dark bg-primary">
      <div className="container-fluid">
        <a
          className="navbar-brand align-text-bottom"
          href={`${process.env.PUBLIC_URL}/`}
        >
          <img
            src="/images/logo/logo.png"
            alt="AnimalSplit logo"
            width="30"
            height="30"
            className="d-inline-block align-top"
          />{" "}
          AnimalSplit
        </a>
        {showActionBtn && (
          <button className="btn btn-primary" onClick={handleActionBtnOnClick}>
            <i className={actionBtnIconClassName} />
          </button>
        )}
      </div>
    </nav>
  );
}
