export const ANIMAL_SPLIT_CORE = {
  development: {
    URL: process.env.REACT_APP_CORE_URL_LOCAL,
  },
  production: {
    URL: process.env.REACT_APP_CORE_URL_PROD,
  },
};

export const ICONS = {
  dog: "🐶",
  cat: "🐱",
  rabbit: "🐰",
  fox: "🦊",
  bear: "🐻",
  panda: "🐼",
  koala: "🐨",
  tiger: "🐯",
  lion: "🦁",
  cow: "🐮",
  pig: "🐷",
  frog: "🐸",
  monkey: "🐵",
};

export const TRANSACTION_CATEGORIES = {
  meal: "🍽",
  snacks: "🍿",
  drinks: "🍹",
  transportation: "🚗",
  parking: "🅿️",
  gas: "⛽️",
  tickets: "🎫",
  accommodation: "🛌",
  rent: "🏠",
  utility: "⚡️",
  groceries: "🛒",
  insurance: "💰",
  uncategorized: "🏷",
};

export const THEME = {
  THEME_COLOR_01: "#8dddc8",
  THEME_COLOR_02: "#4b9347",
  THEME_COLOR_03: "#92cb4c",
  NEAR_WHITE: "#f8f8ff",
  NEAR_BLACK: "#100c08",
  COLOR_ALERT: "#d9595e",
};

export const MODAL_ID = {
  CONNECTION_ERROR: "connection-error-modal-id",
};
