import React from "react";
import ReactDOM from "react-dom";
import { THEME as theme } from "./constants";
import App from "./App";
import "./styles/bootstrap/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

import "./index.css";
import "./styles/css/global.css"
import { ThemeProvider } from "styled-components";
import './i18n';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ThemeProvider>,
  document.getElementById("root")
);
