import React from "react";
import { deleteContributor } from "../services/AnimalSplitCore";
import { toggleModal } from "../utils/utils";
import { ICONS, MODAL_ID } from "../constants";
import { useTranslation } from "react-i18next";

export default function RemoveContributorPrompt(props) {
  const { modalId, groupId, renderGroup, contributor, setIsLoading } = props;
  const { t } = useTranslation();

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      const { _id: contributorId } = contributor;
      const { payload } = await deleteContributor(groupId, contributorId);
      setIsLoading(false);
      renderGroup(payload);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      toggleModal(MODAL_ID.CONNECTION_ERROR);
    }
  };

  return (
    <div className="modal fade" id={modalId} tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {t("participant.delete_confirm.title")}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
            ></button>
          </div>

          <div className="modal-body">
            <p>{`${t("participant.delete_confirm.body1")}${
              ICONS[contributor.icon]
            }${contributor.name}${t("participant.delete_confirm.body2")}`}</p>
            <ul className="fs-6">
              <li>
                <small>{t("participant.delete_confirm.consequence1")}</small>
              </li>
              <li>
                <small>{t("participant.delete_confirm.consequence2")}</small>
              </li>
            </ul>
            <br />
            <p>{t("participant.delete_confirm.confirm")}</p>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              {t("general.close")}
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={handleDelete}
              data-bs-dismiss="modal"
            >
              {t("general.delete")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
