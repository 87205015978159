import { ICONS } from "../constants";
import { useState, useEffect } from "react";
import { amountDataToDisplay } from "../utils/utils";
import { amountInputToData } from "../utils/utils";
import "./ExactTransactionContributorRow.css";
import { useTranslation } from 'react-i18next';

export default function ExactTransactionContributorRow(props) {
  const { t } = useTranslation();
  const {
    contributor,
    dividedAmount,
    setContributorAmountList,
    contributorAmountIsEditing,
    setContributorAmountIsEditing,
    transaction,
    newTransaction,
  } = props;
  const [amount, setAmount] = useState("");
  const exactType = "exact";

  useEffect(() => {
    setContributorAmountList((prevState) => ({
      ...prevState,
      [contributor._id]: { amount: amountInputToData(amount) || 0, share: 0 },
    }));
  }, [amount, contributor, setContributorAmountList]);

  useEffect(() => {
    if (newTransaction) {
      setAmount("0");
    } else {
      if (transaction.type === exactType) {
        setAmount(
          `${amountDataToDisplay(
            transaction.affectedContributors[contributor._id]?.amount ?? 0
          )}`
        );
      } else {
        setAmount(`${dividedAmount}`);
      }
    }
  }, [dividedAmount, contributor, transaction, newTransaction]);

  useEffect(() => {
    if (!contributorAmountIsEditing) {
      setAmount(`${dividedAmount || 0}`);
    }
  }, [dividedAmount, contributorAmountIsEditing]);

  return (
    <div className="d-flex align-items-center">
      <div className="col-6">
        <div className="d-flex align-items-center ms-sm-4">
          <div className="d-inline fs-3 me-1">{ICONS[contributor.icon]}</div>
          <div className="d-inline text-truncate">{contributor.name}</div>
        </div>
      </div>
      <div className="col-6">
        <div className="d-flex justify-content-end me-sm-4">
          <div className="exact-transaction-contributor-row-amount">
            <label className="form-label me-2">
              <small>{t('transaction.amount')}:</small>
            </label>
            <input
              type="number"
              className="form-control"
              value={amount}
              onChange={(e) => {
                setContributorAmountIsEditing(true);
                setAmount(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
