import React, { useState, useCallback, useEffect } from "react";
import { putContributor, deleteContributor } from "../services/AnimalSplitCore";
import SelectContributorIcon from "../components/SelectContributorIcon";
import { toggleModal } from "../utils/utils";
import { MODAL_ID } from "../constants";
import { useTranslation } from "react-i18next";

export default function EditContributor(props) {
  const { t } = useTranslation();
  const { modalId, groupId, contributor, renderGroup, setIsLoading } = props;
  const { _id: contributorId } = contributor;

  const [name, setName] = useState(contributor.name);
  const [contributorIconId, setContributorIconId] = useState(contributor.icon);
  const [formValidated, setFormValidated] = useState(false);

  const handleModalWillShow = useCallback(() => {
    setFormValidated(false);
  }, [setFormValidated]);

  useEffect(() => {
    const editContributorCreationModal = document.getElementById(modalId);
    editContributorCreationModal.addEventListener(
      "show.bs.modal",
      handleModalWillShow
    );

    return () => {
      editContributorCreationModal.removeEventListener(
        "show.bs.modal",
        handleModalWillShow
      );
    };
  }, [modalId, handleModalWillShow]);

  const handleUpdate = async () => {
    if (!isFormValid()) {
      setFormValidated(true);
      return;
    }

    try {
      setIsLoading(true);
      const { payload } = await putContributor(groupId, contributorId, {
        name,
        icon: contributorIconId,
      });
      setIsLoading(false);
      renderGroup(payload);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      toggleModal(MODAL_ID.CONNECTION_ERROR);
    }
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      const { payload } = await deleteContributor(groupId, contributorId);
      setIsLoading(false);
      renderGroup(payload);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      toggleModal(MODAL_ID.CONNECTION_ERROR);
    }
  };

  const isFormValid = () => {
    return name !== "";
  };

  return (
    <div className="modal fade" id={modalId} tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {t("participant.edit_participant")}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
            ></button>
          </div>

          <div className={`modal-body ${formValidated ? "was-validated" : ""}`}>
            <label className="form-label">
              {t("participant.participant_name")}
            </label>
            <div className="d-flex">
              <input
                type="text"
                className="form-control me-2"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <SelectContributorIcon
                setContributorIconId={setContributorIconId}
                contributorIconId={contributorIconId}
              />
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              {t("general.close")}
            </button>
            <button
              type="button"
              className="btn btn-danger"
              data-bs-dismiss="modal"
              onClick={handleDelete}
            >
              {t("general.delete")}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              data-bs-dismiss={`${isFormValid() ? "modal" : ""}`}
              onClick={handleUpdate}
            >
              {t("general.save")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
