import React, { Suspense, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Group from "./containers/Group";
import Landing from "./containers/Landing";
import CreateGroup from "./containers/CreateGroup";
import ErrorBoundary from "./containers/ErrorBoundary";
import Terms from "./containers/Terms";
import LoadingIndicator from "./components/LoadingIndicator";

function App() {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <ErrorBoundary>
      <Suspense fallback="loading">
        <Router
          basename={
            process.env.NODE_ENV === "development" ? "/animal-split" : "/"
          }
        >
          <div>
            <Switch>
              <Route path={`${process.env.PUBLIC_URL}/terms`}>
                <Terms />
              </Route>
              <Route path={`${process.env.PUBLIC_URL}/group/new`}>
                <CreateGroup setIsLoading={setIsLoading} />
              </Route>
              <Route path={`${process.env.PUBLIC_URL}/group/:group_id`}>
                <Group setIsLoading={setIsLoading} />
              </Route>
              <Route path={`${process.env.PUBLIC_URL}/`}>
                <Landing />
              </Route>
            </Switch>
            <LoadingIndicator loading={isLoading} />
          </div>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
