import { useEffect } from "react";
import { ICONS } from "../constants";
import "./SelectContributorIcon.css";
import { useRef } from "react";

export default function SelectContributorIcon(props) {
  const { setContributorIconId, contributorIconId } = props;
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (!contributorIconId) {
      const initialIconId = Object.keys(ICONS)[Math.floor(Math.random() * Object.keys(ICONS).length)];
      setContributorIconId(initialIconId);
    }
  }, [contributorIconId, setContributorIconId]);

  const scrollToView = ()=>{
    dropdownRef.current.focus();
  };

  return (
    <div className="dropdown">
      <button
        className="btn btn-outline-secondary dropdown-toggle"
        data-bs-toggle="dropdown"
        onClick={scrollToView}
      >
        {ICONS[contributorIconId]}
      </button>
      <div
        className="dropdown-menu"
        onClick={(e) => {
          e.preventDefault();
          setContributorIconId(e.target.getAttribute("value"));
        }}
      >
        {Object.keys(ICONS).map((k) => (
          <button
            ref={contributorIconId === k ? dropdownRef:null}
            className={`dropdown-item text-center ${
              contributorIconId === k ? "active" : ""
            }`}
            key={k}
            value={k}
          >
            {ICONS[k]}
          </button>
        ))}
      </div>
    </div>
  );
}
